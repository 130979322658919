import React from 'react';
import { useLocation } from '@reach/router';
import PropTypes from "prop-types"
import Navbar from "./navbar";
import Footer from './footer';
import ButtonTopScroller from '../shared/button-top-scroller';

export const Layout = ({ children }) => {

  const location = useLocation();

  if (location.pathname === "/") {
    return (
      <div style={{minHeight: '300px', minWidth: '260px'}} >
        <main>{children}</main>
        <Footer />
      </div>
    )
  } else {
    return (
      <div className='flex justify-center'>
        <div className="flex flex-col w-full large-screen-limit">
          <Navbar />
          <main style={{minHeight: 'calc(100dvh - 95px - 3rem)'}}>{children}</main>
          <ButtonTopScroller />
          <Footer />
        </div>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
