import * as React from "react"

const Footer = () => {

  return (
    <footer className="flex justify-center items-center w-full bg-white" style={{ minHeight: '3rem', maxHeight: '3rem' }}>
      <p className="text-stone-500 text-sm tracking-widest fft">
        <a href="https://bitbacon.gr" target="_blank" rel="noopener noreferrer">BitBacon © {new Date().getFullYear()}</a>
      </p>
    </footer>
  )
}

export default Footer