import React, { useState, useRef, useEffect, useCallback } from "react";
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from "gatsby";
import "./navbar.css";

const Navbar = () => {

  const navbar = {
    home: "Home",
    art: "Art",
    news: "News",
    about: "About",
    contact: "Contact",
    languageSwitch: {
        english: "En",
        greek: "Gr"
    }
  }

  const logoImage = '../../static/images/shared/logo.webp';
  const logoImageBig = '../../static/images/shared/logo-big.jpg';
  const modalRef = useRef(null);
  const buttonRef = useRef(null);
  const [scrolled, setScrolled] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const setStateMenu = useCallback((state) => {
    setIsOpen(state);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 95) {
        setScrolled(window.scrollY);
      } else {
        setScrolled(95);
      }
    };

    const handleOutsideClick = (event) => {
      if (!modalRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setStateMenu(false);
      }
    }

    window.addEventListener('click', handleOutsideClick);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setStateMenu]);


  return (
    <header>
      <nav>
        <div className="p-6 bg-white flex" style={{ minHeight: '95px', maxHeight: '95px' }}>
          <div className="flex items-center lg:hidden">
            <Link to="/home">
              <StaticImage
                src={logoImage}
                width={120}
                alt="Logo"
                loading="eager"
              />
            </Link>
          </div>
          <div className="items-center hidden lg:flex">
            <Link to="/home">
              <StaticImage
                src={logoImageBig}
                width={260}
                alt="Logo"
                loading="eager"
              />
            </Link>
          </div>
          <div className="flex flex-1 w-full items-center justify-end md:justify-center extra-margin">
            {/* Large screens */}
            <div className="hidden md:flex space-x-16 text-stone-800 text-xl fft">
              <Link
                to="/home"
                activeClassName="underline underline-offset-8"
                className="hover:underline hover:underline-offset-8">
                {/* {t('home')} */}
                {navbar.home}
              </Link>
              <Link
                to="/art"
                partiallyActive={true}
                activeClassName="underline underline-offset-8"
                className="hover:underline hover:underline-offset-8">
                {/* {t('art')} */}
                {navbar.art}
              </Link>
              <Link
                to="/news"
                activeClassName="underline underline-offset-8"
                className="hover:underline hover:underline-offset-8">
                {/* {t('news')} */}
                {navbar.news}
              </Link>
              <Link
                to="/about"
                activeClassName="underline underline-offset-8"
                className="hover:underline hover:underline-offset-8">
                {/* {t('about')} */}
                {navbar.about}
              </Link>
              <Link
                to="/contact"
                activeClassName="underline underline-offset-8"
                className="hover:underline hover:underline-offset-8">
                {/* {t('contact')} */}
                {navbar.contact}
              </Link>

              {/* <button className="text-stone-800 bg-orange-500" onClick={() => changeLanguage('en')}>{t('languageSwitch.english')}</button>
              <button className="text-stone-800 bg-pink-500" onClick={() => changeLanguage('el')}>{t('languageSwitch.greek')}</button> */}

            </div>
            {/* Mobile screens */}
            <button ref={buttonRef} className={`burger-button flex flex-col justify-between cursor-pointer md:hidden ${isOpen ? "open" : ""}`}
              onClick={() => toggleMenu()} aria-label="menu-burger">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
              <div ref={modalRef} className={`menu bg-white bg-opacity-90 fixed ffl ${isOpen ? "open" : ""}`}
                style={{ top: `calc(95px - ${scrolled}px)`, minHeight: `calc(100dvh - 95px + ${scrolled}px)` }}>
                <ul className="text-stone-800 text-lg">
                  <li style={{ padding: 0 }}>
                    <Link
                      to="/home"
                      activeClassName="underline underline-offset-4"
                      className=" hover:underline hover:underline-offset-4 w-full">
                      <div className="w-full p-4 pl-5">
                        {/* {t('home')} */}
                        {navbar.home}
                      </div>
                    </Link>
                  </li>
                  <li style={{ padding: 0 }}>
                    <Link
                      to="/art"
                      activeClassName="underline underline-offset-4"
                      className=" hover:underline hover:underline-offset-4 w-full">
                      <div className="w-full p-4 pl-5">
                        {/* {t('art')} */}
                        {navbar.art}
                      </div>
                    </Link>
                  </li>
                  <li style={{ padding: 0 }}>
                    <Link
                      to="/news"
                      activeClassName="underline underline-offset-4"
                      className=" hover:underline hover:underline-offset-4 w-full">
                      <div className="w-full p-4 pl-5">
                        {/* {t('news')} */}
                        {navbar.news}
                      </div>
                    </Link>
                  </li>
                  <li style={{ padding: 0 }}>
                    <Link
                      to="/about"
                      activeClassName="underline underline-offset-4"
                      className=" hover:underline hover:underline-offset-4 w-full">
                      <div className="w-full p-4 pl-5">
                        {/* {t('about')} */}
                        {navbar.about}
                      </div>
                    </Link>
                  </li>
                  <li style={{ padding: 0 }}>
                    <Link
                      to="/contact"
                      activeClassName="underline underline-offset-4"
                      className=" hover:underline hover:underline-offset-4 w-full">
                      <div className="w-full p-4 pl-5">
                        {/* {t('contact')} */}
                        {navbar.contact}
                      </div>
                    </Link>
                  </li>

                  {/* <button className="text-stone-800 bg-orange-500" onClick={() => changeLanguage('en')}>{t('languageSwitch.english')}</button>
                    <button className="text-stone-800 bg-pink-500" onClick={() => changeLanguage('gr')}>{t('languageSwitch.greek')}</button> */}

                </ul>
              </div>
            </button>
          </div>
        </div>
      </nav>
    </header >
  );
};

export default Navbar;
