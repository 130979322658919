module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-V8LGXQ511F"],"pluginConfig":{"head":false,"respectDNT":true,"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0,"exclude":[]},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://steliosgavalas.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout/layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Stelios Gavalas Official Site","short_name":"SGavalas","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"./src/static/images/favicon.png","icons":[{"src":"./src/static/images/favicon-192.png","sizes":"192x192","type":"image/png"},{"src":"./src/static/images/favicon-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5cbc8bf34d6d243152179f31d6131203"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
