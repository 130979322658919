import React, { useEffect, useRef, useState } from "react";

const ButtonTopScroller = () => {
    const [showButton, setShowButton] = useState(false);
    const buttonTopScrollRef = useRef(null);

    useEffect(() => {
        const scrollFunction = () => {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', scrollFunction);

        return () => {
            window.removeEventListener('scroll', scrollFunction);
        };
    }, []);

    const topFunction = () => {
        document.documentElement.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <button
            ref={buttonTopScrollRef}
            onClick={() => topFunction()}
            aria-label="top-scroll-button"
            className={`rounded-full fft cursor-pointer mr-8 mb-8 z-20 fixed flex items-center justify-center bottom-0 right-0 opacity-60 bg-cyan-100 w-12 h-12 text-pink text-sm tracking-widest ${showButton ? 'block' : 'hidden'}`}>
            <svg
                className="fill-black"
                xmlns="http://www.w3.org/2000/svg"
                height="2em"
                viewBox="0 0 512 512">
                <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
            </svg>
        </button>
    );
};

export default ButtonTopScroller;
